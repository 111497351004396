import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const ConversationStat = ({ title, count, tooltipText }) => {
  return (
    <div className="parent">
      <div className="box1">
        <p>
          {title}{' '}
          <Tooltip title={tooltipText} arrow placement="top">
            <InfoOutlined fontSize="10px" />
          </Tooltip>
        </p>
      </div>
      <p className="ptext">{count}</p>
    </div>
  );
};

export default ConversationStat;